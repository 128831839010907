import axios from 'axios';
import { resourceNotFoundError } from '@/utils/errors';
import { Order } from '@/models/order';
import { CreditCard } from '@/models/creditCard';
import { LOGOUT_REASON_KEY } from '@/consts';
import { Recurrence } from '@/models/recurrence';

export class BffService {
  constructor(host, requesterId, router) {
    this.client = new axios.create({
      baseURL: host,
      timeout: 30000,
      withCredentials: true,
      headers: {
        'x-requester-id': requesterId,
      },
    });

    const onApiSuccess = response => response;
    const onApiError = resp => {
      const response = resp?.response;
      const data = response?.data;
      const status = response?.status;

      if (status === 401) {
        const tag = data?.error?.tag || '';

        if (tag === 'AUTH.USER_SESSION_EXPIRED') {
          const reason = data?.meta?.reason || '';
          sessionStorage.setItem(LOGOUT_REASON_KEY, reason);
        }

        localStorage.clear();
        return router.push({ name: 'logout' });
      }

      if (status === 404) {
        throw resourceNotFoundError;
      }

      return Promise.reject(resp);
    };
    this.client.interceptors.response.use(onApiSuccess, onApiError);
  }

  async getPurchaseHistory() {
    const orders = await this.get('/bff/profile/purchase-history');
    return orders.map(order => Order.fromJson(order));
  }

  async getFailedRecurrences() {
    const failedRecurrences = await this.get('/bff/profile/failed-recurrences');
    return failedRecurrences.map(r => Recurrence.fromJson(r));
  }

  /**
   * @param {string} orderNumber
   * @param {string} vertical
   * @returns {Promise<Order>}
   */
  async getOrderDetails(orderNumber, vertical) {
    const response = await this.get(`/bff/profile/order/${orderNumber}`, vertical);
    const order = Order.fromJson(response);
    return order;
  }

  async getBonusesList(orderNumber, vertical) {
    const response = await this.get(`/bff/ecommerce/catalog/order/${orderNumber}`, vertical);
    return Order.toBonusList(response?.bonuses ?? []);
  }

  async get(url, vertical ) {
    const headers = {};
    if (vertical) { headers['x-vertical'] = vertical }
    return this.client.get(url, { headers }).then(resp => resp.data.data);
  }

  /**
   * @returns {Promise<CreditCard[]>}
   */
  getCreditCards() {
    return this.get('/bff/profile/credit-cards').then(data => data.map(cc => CreditCard.fromJson(cc)));
  }

  createCreditCard(payload) {
    return this.client.post('/bff/profile/credit-cards', payload);
  }

  deleteCreditCard(cardId) {
    return this.client.delete('/bff/profile/credit-cards/'+cardId);
  }
}
