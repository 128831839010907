export const DADOS_COMPLETOS_LOCALSTORAGE_KEY = 'ALL_DATA_FULFILLED';

export const politicaDePrivacidadeHref = 'https://public-miscellaneous.s3-sa-east-1.amazonaws.com/termos/Poli%CC%81tica+de+Privacidade+-+Estrate%CC%81gia+Educacional.pdf';

export const checkoutSteps = [
  { id: 'produto', name: 'Produto' },
  { id: 'identificacao', name: 'Identificação' },
  { id: 'dados', name: 'Dados' },
  { id: 'pagamento', name: 'Pagamento' },
  { id: 'confirmacao', name: 'Confirmação' },
];

export const LOGOUT_REASON_KEY = 'logout_reason';

export const LOGOUT_REASON_MESSAGES = {
  LOGOUT: '',
  INACTIVATION: 'Você foi deslogado, pois o seu usuário não está ativo. Por favor, entre em contato com a central de atendimento ao aluno.',
  ADMIN_DECISION: 'Você foi desconectado. Por favor, realize o login novamente.',
  OVER_MAX_CONCURRENT_LOGIN: 'Você foi deslogado, pois uma nova sessão foi iniciada. Por favor, realize o login novamente.',
};

export const STATUS = {
  PAID: 'paid',
};

export const VENDIBLE_TYPE = {
  LOT: 'lote',
  PRODUCT: 'produto',
};
