export function getTargetFromUrl() {
  try {
    const target = new URL(location.href).searchParams.get('target');
    return target ? decodeURIComponent(target) : '';
  } catch (e) {
    return '';
  }
}

export function isTargetFromSomeAllowedHosts(target = '', allowedHosts = []) {
  try {
    const targetUrl = new URL(target);
    const targetHost = targetUrl.hostname;
    return allowedHosts.some(host => (host === targetHost) || targetHost.endsWith('.' + host));
  } catch (e) {
    return false;
  }
}

export function isTargetFromConcursos(target = '') {
  const concursosHosts = [
    'estrategiaconcursos.com.br',
  ];
  return isTargetFromSomeAllowedHosts(target, concursosHosts);
}

/**
 * Retorna a query string sem o sinal de interrogação
 */
export function getCurrentQueryString() {
  const queryString = location.search ?? '';
  return queryString.startsWith('?') ? queryString.slice(1) : '';
}
